/**
 * Parse the time to string
 * @param {(Object|string|number)}
 * @param {string} cFormat
 * @returns {string}
 */

export const isNullOrEmpty = (value: any) => {
  return !!(value === null || value === '' || value === undefined);
}
