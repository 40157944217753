import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "normalize.css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import { ElMessage } from "element-plus";
import Vant from 'vant';
import 'vant/lib/index.css';
const app = createApp(App);
app.use(store).use(router).use(ElementPlus).use(Vant).mount("#app");

app.config.globalProperties.$message = (msg: string) => {
  return {
    message: msg,
    duration: 1500,
  };
};
app.config.globalProperties.$message.success = (msg: string) => {
  return ElMessage({
    message: msg,
    duration: 1500,
    type: "success",
  });
};
app.config.globalProperties.$message.warning = (msg: string) => {
  return ElMessage({
    message: msg,
    duration: 1500,
    type: "warning",
  });
};
app.config.globalProperties.$message.info = (msg: string) => {
  return ElMessage({
    message: msg,
    duration: 1500,
    type: "info",
  });
};
app.config.globalProperties.$message.error = (msg: string) => {
  return ElMessage({
    message: msg,
    duration: 1500,
    type: "error",
  });
};
