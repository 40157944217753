import db from '@/utils/localstorage';

export default {
  namespaced: true,
  state: {
    basicInfo: db.get('BASIC_INFO', null),
    gradeInfo: db.get('GRADE_INFO', null),
    experienceInfo: db.get('EXPERIENCE_INFO', null),
    applicationInfo: db.get('APPLICATION_INFO', null),
    activityInfo: db.get('ACTIVITY_INFO', null),
    prizeInfo: db.get('PRIZE_INFO', null),
  },
  mutations: {
    setBasicInfo(state: any, val: object) {
      db.save('BASIC_INFO', val);
      state.basicInfo = val;
    },
    setGradeInfo(state: any, val: object) {
      db.save('GRADE_INFO', val);
      state.gradeInfo = val;
    },
    setExperienceInfo(state: any, val: object) {
      db.save('EXPERIENCE_INFO', val);
      state.experienceInfo = val;
    },
    setApplicationInfo(state: any, val: object) {
      db.save('APPLICATION_INFO', val);
      state.applicationInfo = val;
    },
    setActivityInfo(state: any, val: object) {
      db.save('ACTIVITY_INFO', val);
      state.activityInfo = val;
    },
    setPrizeInfo(state: any, val: object) {
      db.save('PRIZE_INFO', val);
      state.prizeInfo = val;
    },
  },
};
