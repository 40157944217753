const db = {
  save(key: any, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  },
  get(key: any, defaultValue: any = {}) {
    let params = localStorage.getItem(key)||'';
    try {
      let jsonObj = JSON.parse(params) || defaultValue;
      return jsonObj;
    } catch (error) {
      return params || defaultValue;
    }
    
    
  },
  remove(key: any) {
    localStorage.removeItem(key);
  },
  clear() {
    const loginType = localStorage.getItem('loginType');
    localStorage.clear();
    sessionStorage.clear();
    if (loginType) {
      localStorage.setItem('loginType', loginType);
    }
  },
};

export default db;
