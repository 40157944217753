import request from '../request';

const authApi = {
  // 校验token
  checkToken(params: any) {
    return request.post('/auth/oauth/check_token', null, {
      params,
      headers: { Authorization: 'Basic enMtY21zOjEyMzQ1Ng==' },
    });
  },
  // 登录请求
  login(params: any) {
    return request.post('/auth/oauth/token', null, {
      params,
      headers: { Authorization: 'Basic enMtY21zOjEyMzQ1Ng==' },
      isNotToken: true,
    });
  },
  // 登陆后获得用户信息
  getPrincipal() {
    return request.get('/auth/account/principal');
  },
  // 通过手机号获取短信验证码
  getSmsCode(mobile: string) {
    return request.get(`/auth/sms/login?mobile=${mobile}`);
  },
  // 登陆后获得用户的动态路由
  getUserRouterByUserId(userId: string) {
    return request.get(`/system/menus/router/${userId}`);
  },
  // 通过token注销令牌
  revokeToken(token: string) {
    return request.delete(`/auth/oauth/token?token=${token}`, {
      headers: { Authorization: 'Basic enMtY21zOjEyMzQ1Ng==' },
    });
  },
  // 通过userId注销令牌
  revokeId(userId: string) {
    return request.delete(`/auth/token/kick-out?userId=${userId}`);
  },
  // 删除用户
  deleteUser(userId: string, currHour: any) {
    return request.delete(`/auth/cms/user/${userId}/cancel?currHour=${currHour}`);
  },
};

export default authApi;
