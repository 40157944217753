import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import db from '@/utils/localstorage';
import authApi from '../api/auth/authApi';
import { isNullOrEmpty } from '@/utils/index';
import { useStore } from 'vuex';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('@/views/dashboard/index.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index.vue'),
  },
  {
    path: '/consultantInformationEntry',
    name: 'ConsultantInformationEntry',
    component: () => import('@/views/consultantInformationEntry/index.vue'),
  },
  {
    path: '/finish',
    name: 'Finish',
    component: () => import('@/views/finish/index.vue'),
  }
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const whiteList = ['/login'];
// 登录时返回的路由
let asyncRouter: any;

router.beforeEach((to, from, next) => {
  const store: any = useStore();
  NProgress.start();
  if (whiteList.indexOf(to.path) !== -1) {
    next();
  } else {
    const token = db.get('ACCESS_TOKEN');
    const user = db.get('USER');
    // storage里的路由信息
    const userRouter = db.get('USER_ROUTER', '');
    
    // 判断storage里面是否已经保存用户信息
    if (token && token.length && !isNullOrEmpty(user)) {
      if (!asyncRouter) {
        if (!userRouter) {
          
          authApi
            .getUserRouterByUserId(user.zsUser.id)
            .then((response: any) => {
              
              asyncRouter = response.data.data.concat([
                {
                  path: '*',
                  name: '404',
                  component: 'error-page/404',
                  hidden: false,
                  alwaysShow: false,
                },
              ]);
              store.commit('account/setRoutes', asyncRouter);
              go(to, next);
            })
            .catch(() => {
              next('/login');
            });
        } else {
          asyncRouter = userRouter;
          go(to, next);
        }
      } else {
        next();
      }
    } else {
      if (to.path === '/login') {
        next();
      } else {
        NProgress.done();
        next('/login');
      }
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});

const go = (to: any, next: any) => {
  next({ ...to, replace: true });
}

export default router
