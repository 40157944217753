import db from '@/utils/localstorage';

export const getAccessToken = () => {
  return db.get('ACCESS_TOKEN', '');
}

export const getRefreshToken = () => {
  return db.get('REFRESH_TOKEN', '');
}

export const getExpireTime = () => {
  return db.get('EXPIRE_TIME', 0);
}
