import db from '@/utils/localstorage';

export default {
  namespaced: true,
  state: {
    accessToken: db.get('ACCESS_TOKEN'),
    refreshToken: db.get('REFRESH_TOKEN'),
    expireTime: db.get('EXPIRE_TIME', 0),
    user: db.get('USER'),
    consultant: db.get('CONSULTANT'),
    permissions: db.get('PERMISSIONS'),
    routes: db.get('USER_ROUTER') || [],
    roles: db.get('ROLES') || [],
    consultantInfo: db.get('CONSULTANT_INFO', null),
  },
  mutations: {
    setAccessToken(state: any, val: string) {
      db.save('ACCESS_TOKEN', val);
      state.accessToken = val;
    },
    setRefreshToken(state: any, val: string) {
      db.save('REFRESH_TOKEN', val);
      state.refreshToken = val;
    },
    setExpireTime(state: any, val: number) {
      db.save('EXPIRE_TIME', val);
      state.expireTime = val;
    },
    setUser(state: any, val: object) {
      db.save('USER', val);
      state.user = val;
    },

    // 咨询师用户Id
    setConsultant(state: any, val: string) {
      db.save('CONSULTANT', val);
      state.consultant = val;
    },
    // 咨询师用户信息
    setConsultantInfo(state: any, val: object) {
      db.save('CONSULTANT_INFO', val);
      state.consultantInfo = val;
    },

    setRoles(state: any, val: any) {
      db.save('ROLES', val);
      state.roles = val;
    },
    setPermissions(state: any, val: any) {
      db.save('PERMISSIONS', val);
      state.permissions = val;
    },
    setRoutes(state: any, val: any) {
      db.save('USER_ROUTER', val);
      state.routes = val;
    },
  },
};
