import axios from "axios";
import { ElMessage } from "element-plus";
import router from "@/router";
import { isNullOrEmpty } from "@/utils/index";
import { getAccessToken } from "@/utils/auth";

const success = 200;

/**
 * 封装axios
 */
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000,
  validateStatus(status) {
    return status === success;
  },
});

// 请求拦截器
request.interceptors.request.use(
  (config) => {
    return addToken(config);
  },
  (err) => {
    console.log(err);
  }
);

// 响应拦截器
request.interceptors.response.use(
  (res) => {
    if (isNullOrEmpty(res?.data)) {
      ElMessage({
        message: "网络异常，请稍后重试~",
        type: "error",
      });
    } else if (res.data.code === 10006) {
      router.push("/login");
    }
    return res;
  },
  (err) => {
    if (
      err.code === "ECONNABORTED" ||
      err.message === "Network Error" ||
      err.message.includes("timeout")
    ) {
      return Promise.reject("请求超时");
    }
    if (!err || !err.response || err.response.status === 204) return err;
    console.log("响应拦截器异常, err.response:", err.response);
    switch (err.response.status) {
      case 400:
        if (err.response.data.code === 20013) {
          router.push("/login");
        }
        break;
      case 401:
        ElMessage({
          message: err.response.data.message,
          type: "error",
          duration: 5000,
        });
        router.push("/login");
        break;
      case 403:
        ElMessage({
          message: "拒绝访问",
          type: "error",
        });
        break;
      case 404:
        ElMessage({
          message: "请求的资源未找到",
          type: "error",
        });
        break;
      case 503:
        ElMessage({
          message: "服务未启动",
          type: "error",
        });
        break;
      default:
        ElMessage({
          message: "未知错误",
          type: "error",
        });
    }

    return Promise.reject(err);
  }
);

// 获取token
const addToken = (config: any) => {
  const token = getAccessToken();
  const Authorization = config.headers.Authorization;
  if (!config.isNotToken) {
    if (!isNullOrEmpty(Authorization)) {
      config.headers["Authorization"] = Authorization;
    } else if (isNullOrEmpty(Authorization) && token) {
      config.headers["Authorization"] = "bearer " + token;
    }
  }
  return config;
}

export default request;
